import React, { useState, useRef, useEffect, useContext} from "react"
import { Link, navigate } from "gatsby"
import { FirebaseContext } from "../Firebase"
import uuid from "uuid"

const ProfileMenu = () => {
    const { firebase, usuario } = useContext(FirebaseContext);
    const [isOpen, setIsOpen] = useState(false)
    const activatorRef = useRef()
    const listRef = useRef()

    const handleClick = () => {
        setIsOpen(!isOpen)
    }
    const handleClickOutside = (event) => {
        if (listRef.current.contains(event.target) || activatorRef.current.contains(event.target)) {
            return
        }
        setIsOpen(false)
    }
    useEffect(() => {

        if (isOpen) {
          document.addEventListener("mousedown", handleClickOutside)
        } else {
          document.removeEventListener("mousedown", handleClickOutside)
        }
        // clean up on unmount
        return function cleanup() {
          document.removeEventListener("mousedown", handleClickOutside)
        }
      }, [isOpen])


    return (
        <div
            className="relative"
        >
        <span
            ref={activatorRef}
            className="text-dpurple hover:text-dpink transition-all capitalize px-4 NexaTextDemoLight font-bold flex items-center max-w-xs"
            role="button" tabIndex={0} onKeyPress={handleClick} 
            onClick={handleClick}
        >  
            <p className="mr-2">{usuario.displayName}</p>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-fit inline" width="36" height="36" viewBox="0 0 24 24" strokeWidth="1.25" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <circle cx="12" cy="12" r="9"></circle>
                    <circle cx="12" cy="10" r="3"></circle>
                    <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"></path>
                </svg>
                <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
        </span>
        <div
            ref={listRef}
            id={`list${uuid.v4()}`}
            className={
                ` origin-top-right absolute right-3 p-2 mt-1 w-32 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ` +
                (isOpen ? 'block' : 'hidden')
            }
        >
                <div className="text-gray-700 block px-4 py-2 text-sm hover:text-dpink">
                    <Link to="/perfil">Mi cuenta</Link>
                </div>
                <div className="text-gray-700 block px-4 py-2 text-sm hover:text-dpink">
                    <a href="/logout"
                    onClick={event => {
                        event.preventDefault()
                        firebase.auth.signOut().then(() => {
                            navigate(`/login`)
                        })
                      }}
                    >Logout</a>
                </div>
        </div>
    </div>
    )
}
export default ProfileMenu