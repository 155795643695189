import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';
import  { currencyFormat} from "../Entidades/Proyecto";

const StartupCard = ({ uid, startup }) => {
  const tipo = startup.tipoProyecto.toLowerCase();

  return (
    <Wrapper className="bg-white mb-2 max-w-screen-sm lg:max-w-screen-lg">
      <section className="bg-white w-full p-3 mt-3 shadow-md">
        <div className="flex flex-wrap m-0">
          <div className="flex flex-row max-w-full w-full lg:flex-col lg:max-w-1/4 lg:w-1/4 flex-grow-0 flex-shrink-0 p-2 mx-auto justify-center">
            {tipo === "ecommerce" ? (
                <StaticImage src="../../images/types/ecommerce.png" alt="" layout="fixed" height={170}/>
              ) : (
                tipo === "marketplace" ? 
                  <StaticImage src="../../images/types/marketplace.png" alt="" layout="fixed" height={170}/> : (
                    (
                      tipo === "app" ? 
                        <StaticImage src="../../images/types/app.png" alt="" layout="fixed" height={170}/> : (
                        <StaticImage src="../../images/types/shopify.png" alt="" layout="fixed" height={170}/>
                      ))))}
          </div>
          <div className="flex-row max-w-full w-full lg:flex-col lg:w-3/4 lg:max-w-3/4 px-1 justify-between">
            <div className="flex flex-wrap -mx-1">
              <div className="flex flex-col max-w-full w-full lg:max-w-3/4 lg:w-3/4 flex-grow-0 flex-shrink-0 p-2">
                <div className="w-full nexabold pb-4 whitespace-nowrap overflow-ellipsis overflow-hidden">Nombre Oculto</div>
                <div className="flex flex-nowrap justify-between">
                  <div className="">
                    <div className="text-gray-500 text-xs">Type</div>
                    <div className="nexabold text-dblue"><Link to={"/startups/"+tipo}>{startup.tipoProyecto}</Link></div>
                  </div>
                  <div className="">
                    <div className="text-gray-500 text-xs">Monetization</div>
                    <div className="nexabold text-dpurple">{startup.businessModel}</div>
                  </div>
                  <div className="">
                    <div className="text-gray-500 text-xs">Ventas Anuales</div>
                    <div className="nexabold">
                      <span>{currencyFormat(startup.ventasAnuales)}</span>
                    </div>
                  </div>
                </div>
                <p className="text-gray-500 mt-2 mb-0 text-sm">{startup.descripcion}</p>
              </div>
              <div className="hidden lg:flex flex-col max-w-1/4 w-1/4 flex-grow-0 flex-shrink-0 p-2 items-end justify-between">
                <div className="text-right">
                  <div className="text-gray-500">Precio Inicial</div>
                  <div className="nexabold mt-2 mb-0 text-xl text-dpurple">
                    <span>{startup.precioVenta===0 ? 
                          "Negociable" : 
                          currencyFormat(startup.precioVenta)}</span>
                  </div>
                </div>
              </div>
              <div className="lg:hidden flex-row max-w-full w-full p-2">
                <div className="w-full">
                  <hr/>
                  </div>
                  <div className="w-full">
                    <div className="text-gray-500 left inline mr-10">Precio Inicial</div>
                    <div className="nexabold text-xl right inline">
                      <span>{startup.precioVenta===0 ? 
                          "Negociable" : 
                          currencyFormat(startup.precioVenta)}</span>
                    </div>
                  </div>
                </div>
            </div>
            <div className="flex flex-wrap -mx-1 mt-0.5 items-center">
              <div className="grow basis-0 max-w-full min-w-0 w-0">
                <ul className="flex flex-col sm:flex-row sm:items-end items-start justify-start text-sm">
                  
                </ul>
              </div>
              <div className="grow-0 shrink-0 basis-auto w-auto max-w-full p-1">
              <Link
                to={"/startups/"+uid} 
                className="pink-button"
              >
                Ver más
              </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

// Styling Only
const Wrapper = styled.div``;


export default StartupCard;
