import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"
import { FirebaseContext } from "./Firebase"
import { getFirestore, doc, setDoc } from "firebase/firestore"

const Profile = () => {

  const { firebase, usuario, setUsuario, perfilUsuario, setPerfilUsuario} = useContext(FirebaseContext);
  const [userInfo, setUserInfo] = useState(usuario);
  const [userProfile, setUserProfile] = useState(perfilUsuario);
  const [error, setError] = useState('')
  const [modified, setModified] = useState(false)


 

  useEffect(() => {
    if (!usuario) {
      navigate("/registro/nuevo");
      return;
    } 

    if (!perfilUsuario) {
      navigate("/registro/completo");
      return;
    } 

  }, [usuario, perfilUsuario]);


  const handleUserInfoChange = e  => {
    setUserInfo({...userInfo, [e.target.name]:e.target.value})
    setModified(true)
  }

  const handlesetUserProfileChange = e  => {
    setUserProfile({...userProfile, [e.target.name]:e.target.value})
    setModified(true)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    setError("")
    
    if (userInfo.displayName !== usuario.displayName) {
      firebase.auth.currentUser.updateProfile({ displayName: userInfo.displayName})
        .then(() => {
          setDoc(doc(getFirestore(), "users", usuario.uid), {
            uid: usuario.uid,
            nombreEmpresa: userProfile.nombreEmpresa,
            telefono: userProfile.telefono
          })
        })
        .then(() => {
          setUsuario (firebase.auth.currentUser)
          setModified(false)
          
        })
        .catch(err => {
          setError(err.message)
        })
    } else {
      setDoc(doc(getFirestore(), "users", usuario.uid), {
          uid: usuario.uid,
          nombreEmpresa: userProfile.nombreEmpresa,
          telefono: userProfile.telefono
        })
        .then(() => {
          setPerfilUsuario(userProfile)
          setModified(false)
          
        })
      .catch(err => {
        setError(err.message)
      })
    }

  return false

}

  return (
    <div className="w-full mx-auto px-2 py-1">
    <div className="bg-white mt-5 p-4">
      <h1 className="pb-4">Mi cuenta</h1>
      <div className="flex flex-wrap -m-3.5"> {/* ROW */}
     
        <div className="max-w-xs w-80 p-4 border-r"> {/* COL */}
          <div className="w-full">
            <a href="/perfil" className="flex hover:bg-dpink hover:text-white p-4 active:bg-dpink active:text-white" >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-lock-open" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <rect x="5" y="11" width="14" height="10" rx="2"></rect>
                <circle cx="12" cy="16" r="1"></circle>
                <path d="M8 11v-5a4 4 0 0 1 8 0"></path>
              </svg>
              <span className="pl-2">Perfil</span>
            </a>
          </div>
        </div>
        <div className="w-full max-w-full md:flex-grow md:w-0 p-4 px-6" > {/* COL */}
          <div className="max-w-4xl">
            <h2 className="pt-0">Perfil</h2>
            <form name="Profile" onSubmit={handleSubmit}>
              <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                <p>Email</p>
                <span className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple pl-4 focus:outline-none focus:bg-white focus:text-gray-900">
                  {userInfo.email}
                </span>
              </div>
              <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                <label htmlFor="displayName">Nombre Completo</label>
                <input
                  type="text"
                  name="displayName"
                  id="displayName"
                  className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
                  autoComplete="off"
                  value={userInfo.displayName}
                  onChange={handleUserInfoChange}
                />
              </div>
              
              <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                <label htmlFor="nombreEmpresa">Empresa</label>
                {userProfile && 
                  <input
                    type="text"
                    name="nombreEmpresa"
                    id="nombreEmpresa"
                    className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
                    autoComplete="off"
                    required
                    value={userProfile.nombreEmpresa}
                    onChange={handlesetUserProfileChange}
                  />
                }
              </div>

              <div className="relative flex sm:block flex-col-reverse w-full pb-4">
                <label htmlFor="telefono">Teléfono</label>
                {userProfile && 
                  <input
                    type="text"
                    name="telefono"
                    id="telefono"
                    pattern="(\+?\d{1,3}\s?)?(\d{1,8})"
                    className="py-4 w-full mb-4 sm:mb-0 text-sm text-dpurple bg-white border border-dgrey rounded-lg pl-4 focus:outline-none focus:bg-white focus:text-gray-900"
                    autoComplete="off"
                    required
                    value={userProfile.telefono}
                    onChange={handlesetUserProfileChange}
                    placeholder="+CCXXXYYYZZZ"
                  />
                }
              </div>

              <div className="relative flex sm:block flex-col-reverse w-1/3 pb-4">
                {error ? (
                  <span className="text-red-600 capitalize nexaTextBold mb-4">
                    {error}
                  </span>
                ) : null}
                <button
                  type="submit" 
                  className="pink-button"
                  disabled={!modified}
                >
                  Actualizar
                </button>
              </div>
            </form>
                
          </div>
        </div>
        
        
      </div>
    </div>
    </div>
  )
}

export default Profile