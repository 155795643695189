import * as React from "react"
import Layout from "../components/Layout"
import { Router } from "@reach/router"
import PrivateRoute from "../components/privateRoute"
import Profile from "../components/profile"
import Login from "../components/login"
import Registro from "../components/Registro"
import RegistroCompleto from "../components/RegistroCompleto"
import Proyectos from "../components/Directorio/Proyectos"
import RecuperarContrasena from "../components/RecuperarContrasena"
import NuevaStartup from "../components/Startups/Nueva"
import Startup from "../components/Directorio/Startup"
import StartupMessages from "../components/Directorio/StartupMessages"
import Mensajes from "../components/Mensajes/Mensajes"


const IndexPage = () => {
    return (
        <Layout>
            <Router basepath="/">
                <PrivateRoute path="/perfil" component={Profile} />
                <PrivateRoute path="/proyectos" component={Proyectos} />
                <PrivateRoute path="/startups/:id" component={Startup} />
                <PrivateRoute path="/startups/:id/mensajes/:user" component={StartupMessages} />
                <PrivateRoute path="/mensajes" component={Mensajes} />
                <Login path="/login" />
                <Registro path="/registro/nuevo"/>
                <RegistroCompleto path="/registro/completo"/>
                <RecuperarContrasena path="/recuperar-contrasena"/>
                <NuevaStartup path="/nueva" />
            </Router>
        </Layout>
    );
 }
      
export default IndexPage
